<template>
  <v-card-text>
    <div class="font-weight-bold body-1 mt-2">
      Quest : {{ report.reportable.title }}
    </div>
  </v-card-text>
</template>
<script>
export default {
  props: ['report'],
  computed: {}
}
</script>
